var options = {};
options.middleware = [{}];

export function form_wizard_init(options_custom = {}) {
    options.start = options_custom.start || 0;
    if (options_custom.middleware) {
        // options.middleware['function'] = typeof options_custom.middleware['function'] != 'undefined' ? options_custom.middleware['function'] : null;
        // options.middleware['indexes'] = typeof options_custom.middleware['indexes'] != 'undefined' ? options_custom.middleware['indexes'] : null;
        options.middleware = options_custom.middleware;
    }

    $('.form-wizard').parsley({
        errorClass: 'is-invalid'
            // errorTemplate: '<span class="error-msg"></span>',
    });

    $('.form-wizard .form-section').each(function(i, section) {
        $(section).find(':input').attr('data-parsley-group', 'block-' + i);

        // $(this).find(':input').on('keyup', function() {
        //     var input = $(this);
        //     if ($(this).hasClass('parsley-error')) {
        //         input.addClass('is-invalid');
        //     } else {
        //         input.removeClass('is-invalid');
        //     }
        // });

        // $(this).parsley().on('field:success', function() {
        //     alert('asdf');
        // }).on('field:validated', function() {
        //     if ($(this).parsley().isValid()) {
        //         alert('asdfasdf');
        //     }
        // });
    });

    $('.form-wizard .form-navigation #form-navigation-next').on('click', function() {
        form_wizard_navigate_next();
    });

    $('.form-wizard .form-navigation #form-navigation-previous').on('click', function() {
        form_wizard_navigate_previous();
    });

    $('.form-wizard').on('submit', function(event) {
        event.preventDefault();
        // // event.stopPropagation();

        // if (options.middleware.length > 0) {
        //     if (options.middleware['indexes'] != null) {
        //         if (Array.isArray(options.middleware['indexes'])) {
        //             if (options.middleware['indexes'].includes(form_wizard_current())) {
        //                 $.when(
        //                     options.middleware['function']()
        //                 ).done(function() {
        //                     $('.form-wizard').unbind('submit').submit();
        //                 });
        //             } else {
        //                 $('.form-wizard').unbind('submit').submit();
        //             }
        //         } else {
        //             if (form_wizard_current() == options.middleware['indexes']) {
        //                 $.when(
        //                     options.middleware['function']()
        //                 ).done(function() {
        //                     $('.form-wizard').unbind('submit').submit();
        //                 });
        //             } else {
        //                 $('.form-wizard').unbind('submit').submit();
        //             }
        //         }
        //     } else {
        //         $('.form-wizard').unbind('submit').submit();
        //     }
        // }

        if (options.middleware) {
            let proceed = true;
            options.middleware.forEach(function(i) {
                form_wizard_middleware(i) ? true : proceed = false;
            });
            proceed ? $('.form-wizard').unbind('submit').submit() : false;
        } else {
            $('.form-wizard').unbind('submit').submit();
        }
    });

    form_wizard_navigate(options.start);
}

export function form_wizard_current() {
    return $('.form-wizard .form-section').index($('.form-wizard .form-section').filter('.current'));
}

export function form_wizard_sections_length() {
    return $('.form-wizard .form-section').length;
}

export function form_wizard_navigate(i) {
    $('.form-wizard .form-section').removeClass('current');
    $('.form-wizard .form-section').eq(i).addClass('current');
    $('.form-wizard .form-navigation #form-navigation-previous').toggle(i > 0);
    $('.form-wizard .form-navigation #form-navigation-next').toggle(i < $('.form-wizard .form-section').length - 1);
    $('.form-wizard .form-navigation #form-navigation-submit').toggle(i === $('.form-wizard .form-section').length - 1);
}

export function form_wizard_navigate_next_forced() {
    form_wizard_navigate(form_wizard_current() + 1);
}

export function form_wizard_navigate_next() {
    $('.form-wizard').parsley().whenValidate({
        group: 'block-' + form_wizard_current()
    }).done(function() {
        if (options.middleware) {
            let proceed = true;
            options.middleware.forEach(function(i) {
                form_wizard_middleware(i) ? true : proceed = false;
            });
            proceed ? form_wizard_navigate(form_wizard_current() + 1) : false;
        } else {
            form_wizard_navigate(form_wizard_current() + 1);
        }
    });
}

export function form_wizard_navigate_previous() {
    form_wizard_navigate(form_wizard_current() - 1);
}

export function form_wizard_middleware(middleware) {
    let proceed = true;
    if (middleware.function) {
        if (middleware.indexes != null) {
            if (Array.isArray(middleware.indexes)) {
                if (middleware.indexes.includes(form_wizard_current())) {
                    middleware.function() === false ? proceed = false : null;
                }
            } else {
                if (form_wizard_current() == middleware.indexes) {
                    middleware.function() === false ? proceed = false : null;
                }
            }
        } else {
            middleware.function() === false ? proceed = false : null;
        }
    }
    return proceed;
}