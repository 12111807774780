/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Jquery v3.4.1
window.$ = window.jQuery = require('jquery');
require('jquery-validation');

import Popper from 'popper.js';
window.Popper = Popper;

// Bootstrap JS
require('bootstrap');
require('bootstrap/js/dist/tooltip.js');

//Datatables BS4
require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-responsive-bs4');

// Moment
window.moment = require('moment');
require('moment-timezone');
moment().format();

// Jquery UI v1.12.1 - datepicker
require('jquery-ui/ui/widgets/datepicker.js');

// Date Range Picker
require('daterangepicker/daterangepicker.js');

// AOS animations
window.AOS = require('aos');

// Select2 (dropdown)
require('select2/dist/js/select2.full.js');

// Custom Scrollbar for Sidebar
require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min');

// Toast Notif
window.toastr = require('toastr');

require('smartwizard/dist/js/jquery.smartWizard.min.js');

require('@fullcalendar/core');
require('@fullcalendar/interaction');
require('@fullcalendar/daygrid');
require('@fullcalendar/timegrid');
require('@fullcalendar/list');
require('@fullcalendar/scrollgrid');
require('@fullcalendar/timeline');
require('@fullcalendar/bootstrap');
require('@fullcalendar/moment');
require('@fullcalendar/moment-timezone');
require('bootstrap4-toggle');
import { Calendar } from '@fullcalendar/core';
import interaction from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timelinePlugin from '@fullcalendar/timeline';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
window.Calendar = { Calendar };
window.interaction = interaction;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.scrollGridPlugin = scrollGridPlugin;
window.timelinePlugin = timelinePlugin;
window.bootstrapPlugin = bootstrapPlugin;
window.momentPlugin = momentPlugin;
window.momentTimezonePlugin = momentTimezonePlugin;

require('google-libphonenumber/dist/libphonenumber.js');
import { PhoneNumberUtil } from 'google-libphonenumber';
window.PhoneNumberUtil = { PhoneNumberUtil };
window.AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
window.PNF = require('google-libphonenumber').PhoneNumberFormat;

require('./components/ReadOnlyInput');

import tinymce from 'tinymce/tinymce'
window.tinymce = tinymce;
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons';   
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';
// Plugins
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/hr/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/template/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/preview/plugin';

import { form_wizard_init, form_wizard_navigate, form_wizard_current, form_wizard_navigate_previous, form_wizard_navigate_next, form_wizard_navigate_next_forced } from './form-wizard';
window.form_wizard_init = form_wizard_init;
window.form_wizard_navigate = form_wizard_navigate;
window.form_wizard_current = form_wizard_current;
window.form_wizard_navigate_previous = form_wizard_navigate_previous;
window.form_wizard_navigate_next = form_wizard_navigate_next;
window.form_wizard_navigate_next_forced = form_wizard_navigate_next_forced;

import parsley from 'parsleyjs';
window.parsley = parsley;

require('bootstrap-datepicker/dist/js/bootstrap-datepicker.js');

require('./components/ReadOnlyInput');

