import React from 'react';
import ReactDOM from 'react-dom';

function ReadOnlyInput(props) {
    let label = props.label;
    let value = props.value;

    return (
        <div className='form-group'>
            <label>{label}</label>
            <input className='form-control' readOnly disabled value={value}/>
        </div>
    );
}

export default ReadOnlyInput;

$(document).ready(function(){
    let els = document.getElementsByClassName('react-read-only-input');
    for(let el of els){
        let props = Object.assign({}, el.dataset);

        ReactDOM.render(<ReadOnlyInput {...props}/>, el);
    }
});