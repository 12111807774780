/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Jquery v3.4.1
window.$ = window.jQuery = require('jquery');
require('jquery-validation');

import Popper from 'popper.js';
window.Popper = Popper;

// Bootstrap JS
require('bootstrap');
require('bootstrap/js/dist/tooltip.js');

//Datatables BS4
require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-responsive-bs4');

// Moment
window.moment = require('moment');
require('moment-timezone');
moment().format();

// Jquery UI v1.12.1 - datepicker
require('jquery-ui/ui/widgets/datepicker.js');

// Date Range Picker
require('daterangepicker/daterangepicker.js');

// AOS animations
window.AOS = require('aos');

// Select2 (dropdown)
require('select2/dist/js/select2.full.js');

// Custom Scrollbar for Sidebar
require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min');

// Toast Notif
window.toastr = require('toastr');

require('smartwizard/dist/js/jquery.smartWizard.min.js');

require('@fullcalendar/core');
require('@fullcalendar/interaction');
require('@fullcalendar/daygrid');
require('@fullcalendar/timegrid');
require('@fullcalendar/list');
require('@fullcalendar/scrollgrid');
require('@fullcalendar/timeline');
require('@fullcalendar/bootstrap');
require('@fullcalendar/moment');
require('@fullcalendar/moment-timezone');
require('bootstrap4-toggle');
import { Calendar } from '@fullcalendar/core';
import interaction from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timelinePlugin from '@fullcalendar/timeline';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
window.Calendar = { Calendar };
window.interaction = interaction;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.scrollGridPlugin = scrollGridPlugin;
window.timelinePlugin = timelinePlugin;
window.bootstrapPlugin = bootstrapPlugin;
window.momentPlugin = momentPlugin;
window.momentTimezonePlugin = momentTimezonePlugin;

require('google-libphonenumber/dist/libphonenumber.js');
import { PhoneNumberUtil } from 'google-libphonenumber';
window.PhoneNumberUtil = { PhoneNumberUtil };
window.AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
window.PNF = require('google-libphonenumber').PhoneNumberFormat;

require('./components/ReadOnlyInput');

import tinymce from 'tinymce/tinymce'
window.tinymce = tinymce;
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

import { form_wizard_init, form_wizard_navigate, form_wizard_current, form_wizard_navigate_previous, form_wizard_navigate_next, form_wizard_navigate_next_forced } from './form-wizard';
window.form_wizard_init = form_wizard_init;
window.form_wizard_navigate = form_wizard_navigate;
window.form_wizard_current = form_wizard_current;
window.form_wizard_navigate_previous = form_wizard_navigate_previous;
window.form_wizard_navigate_next = form_wizard_navigate_next;
window.form_wizard_navigate_next_forced = form_wizard_navigate_next_forced;

import parsley from 'parsleyjs';
window.parsley = parsley;

require('bootstrap-datepicker/dist/js/bootstrap-datepicker.js');

require('./components/ReadOnlyInput');

